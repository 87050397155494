import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------

export interface updateThirdConfigParam {
    haodankuKey?:any
    dataokeAppSecret?:any
    dataokeAppKey?:any
}

export interface updateConfigParam {
    taobaoAppSecret?:any
    taobaoAppKey?:any
}

export interface bindChannelPidParam {
    channelPid?:any
}

export interface taoBaoAppAuthParam {
    code?:any
}
// -------接口列------

/**
 * [后台]加载第三方平台接口配置信息
 * @notes 
 */
export function loadThirdPlatformConfig() {
    return get('/taoke/taobaoConfig/loadThirdPlatformConfig');
}

/**
 * [后台]绑定保存通道ID
 * @notes 
 */
export function bindChannelPid(param:bindChannelPidParam) {
    return get('/taoke/taobaoConfig/bindChannelPid', param);
}

/**
 * [后台]拉取邀请码-需先获取授权
 * @notes 
 */
export function loadInviteCode() {
    return get('/taoke/taobaoConfig/loadInviteCode');
}

/**
 * [后台]查询接入的第三方商城
 * @notes 
 */
export function queryShopChannel() {
    return get('/taoke/taobaoConfig/queryShopChannel');
}

/**
 * [后台]通过code绑定淘宝的token
 * @notes 
 */
export function taoBaoAppAuth(param:taoBaoAppAuthParam) {
    return get('/taoke/taobaoConfig/auth', param);
}

/**
 * [后台]加载淘宝配置
 * @notes 
 */
export function loadTaobaoConfig() {
    return get('/taoke/taobaoConfig/loadTaobaoConfig');
}

/**
 * [后台]删除淘宝授权信息
 * @notes 
 */
export function removeTaobaoAuth() {
    return get('/taoke/taobaoConfig/removeTaobaoAuth');
}

/**
 * [后台]加载淘宝授权信息
 * @notes 
 */
export function loadTaobaoAuth() {
    return get('/taoke/taobaoConfig/loadTaobaoAuth');
}

/**
 * [后台]更新保存三方平台配置信息
 * @notes 
 */
export function updateThirdConfig(param:updateThirdConfigParam) {
    return post('/taoke/taobaoConfig/updateThirdConfig', param);
}

/**
 * [后台]保存更新淘宝配置
 * @notes 
 */
export function updateConfig(param:updateConfigParam) {
    return post('/taoke/taobaoConfig/updateConfig', param);
}


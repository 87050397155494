
import { Vue, Component } from 'vue-property-decorator';
import JtAvatarUpload from "@/components/JtAvatarUpload/index.vue";
import { Loading } from "element-ui";
import {
  bindChannelPid,
  loadInviteCode,
  loadTaobaoAuth,
  loadTaobaoConfig,
  removeTaobaoAuth,
  taoBaoAppAuth,
  updateConfig
} from "@/api/taoke/tkTaobaoConfig";
import { removeApp } from "@/api/system/appConfig";

@Component({
  name: 'taobaoConfig',
  components: { JtAvatarUpload }
})
export default class extends Vue {
  taobaoConfig:{
    taobaoAppKey:string
    taobaoAppSecret:string
  } = {
    taobaoAppKey: '',
    taobaoAppSecret: ''
  }
  isEdit = false
  editPID = false
  taobaoAuth = {
    taobaoAccount: null,
    channelPid: null,
    taobaoInviteCode: null,
    taobaoToken: null,
    taobaoTokenExpire: null,
    taobaoAuthExpire: null,
    taobaoRefreshToken: null,
    taobaoRefreshTokenExpire: null
  }

  mounted(){
    this.doAuthTaobao();
    this.loadTaobaoConfig();
  }

  loadTaobaoConfig(){
    loadTaobaoConfig().then(res => {
      this.taobaoConfig = res.data
    })
  }
  loadTaobaoAuth(){
    loadTaobaoAuth().then(res=>{
      this.taobaoAuth = res.data
    })
  }
  //执行淘宝授权
  doAuthTaobao(){
    const code = this.$route.query.code;
    if (code){
      const loadingInstance = Loading.service({
        fullscreen: true,
        lock: true,
        text: "授权处理中..."
      });
      taoBaoAppAuth({ code }).then(res=>{
        this.loadTaobaoAuth();
      }).finally(() =>{
        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
      })
    }else {
      this.loadTaobaoAuth()
    }
  }
  handleSubmitEdit(){
    updateConfig(this.taobaoConfig).then(res => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.isEdit = false
    })
  }
  handleEdit(isEdit:boolean){
    this.isEdit = isEdit
  }
  redirectTaobaoAuth(){
    let localURL = location.href;
    const index = localURL.indexOf("?")
    if (index > 0) {
      localURL = localURL.substring(0, index);
    }
    location.href = "https://oauth.taobao.com/authorize?response_type=code&client_id=" + this.taobaoConfig.taobaoAppKey + "&redirect_uri=" + encodeURIComponent(localURL) + "&state=1212&view=web";
  }
  removeTaobaoAuth(){
    this.$confirm('是否删除当前授权信息?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      removeTaobaoAuth().then(res=>{
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.loadTaobaoAuth();
      })
    }).catch(() => {
      this.$message({
        type: 'info',
        message: '已取消当前操作'
      });
    })
  }
  handleInviteCode(){
    loadInviteCode().then(res=>{
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.loadTaobaoAuth()
    })
  }
  handlePID(){
    bindChannelPid({ channelPid: this.taobaoAuth.channelPid }).then(res=>{
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.editPID = false
    })
  }
}
